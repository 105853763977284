/**
 * This logic is for the testking purposes (sled with experiments)
 * https://github.com/wix-private/santa-editor/search?q=WITH_LARGE_NUMBER_OF_OPEN_EXPERIMENTS
 * https://github.com/wix-private/santa-integration-tests/search?q=WITH_LARGE_NUMBER_OF_OPEN_EXPERIMENTS
 */
export function getLargeNumberOfOpenExperiments() {
  if (
    typeof window.LARGE_NUMBER_OF_OPEN_EXPERIMENTS !== 'string' ||
    window.LARGE_NUMBER_OF_OPEN_EXPERIMENTS.length === 0
  ) {
    return {};
  }

  return Object.fromEntries<'new'>(
    window.LARGE_NUMBER_OF_OPEN_EXPERIMENTS.split(',').map((experiment) => [
      experiment,
      'new',
    ]),
  );
}
