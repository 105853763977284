import { startup } from './startup.esbuild';

//hotfixes, todo rm
window.santaBase = 'https://static.parastorage.com/services/santa/1.11783.0';
///end hotfixes

startup({
  isInsideEditorX: false,
  loadMainCss: true,
  measureFrameRate: true,
  shouldBiErrorsAndFedops: true,
  shouldRender: true,
  shouldLoadPolyfills: true,
});
