const MOBILE_PANELS_NAMES = [
  'mobileHidden',
  'mobileBackground',
  'mobileSiteSettingsPanel',
  'mobileElements',
  'mobileLayoutOptimizer',
];

function getOpenPanelQueryParam(
  editorQuery: Record<string, string>,
): string | null {
  for (const key of Object.keys(editorQuery)) {
    if (key.toLowerCase() === 'openpanel') {
      return editorQuery[key];
    }
  }

  return null;
}

function getNormalizedPanelKey(openPanelParam: string | null) {
  return openPanelParam?.split('.', 1)[0]?.toLowerCase();
}

function getIsMobilePanel(panelKey: string) {
  return MOBILE_PANELS_NAMES.map((name) => name.toLowerCase()).includes(
    panelKey,
  );
}

function shouldLoadMobileEditor(editorQuery: Record<string, string>): boolean {
  const openPanelParam = getOpenPanelQueryParam(editorQuery);
  const panelKey = getNormalizedPanelKey(openPanelParam);
  const isMobilePanel = panelKey === 'mobile' || getIsMobilePanel(panelKey);

  return isMobilePanel;
}

export { shouldLoadMobileEditor };
