const DUPLICATE_INDEX_KEY = 'editorTabDuplicateIndex';

function once<T extends (...args: any) => any>(
  fn: T,
): () => T extends (...args: any) => infer R ? R : any {
  let isCalled = false;
  let callResult: ReturnType<T> = null;
  return function () {
    if (isCalled) {
      return callResult;
    }
    isCalled = true;
    callResult = fn(...arguments);

    return callResult;
  };
}

function getDuplicateIndex(): number {
  return parseInt(sessionStorage.getItem(DUPLICATE_INDEX_KEY) || '0', 10);
}

function setDuplicateIndex(duplicateIndex: number) {
  sessionStorage.setItem(DUPLICATE_INDEX_KEY, String(duplicateIndex));
}

export function initTabDuplicatePolice() {
  // on initial browser tab loading, tab index in the session storage is empty (with 0 as default value), which mean that tab is not duplicated.
  // after tab is loaded we increment tab index to "1" and store to the locale storage, so when user duplicate the tab, initial value will be "1" instead of "0", which means, that this tab is duplicated.
  // on tab close/reload/back/forward we reset (decrement) index using `onunload` or `onbeforeunload` callback, so after initial tab loaded again, tab index is "0"
  const duplicateIndex = getDuplicateIndex();
  const isTabDuplicated = duplicateIndex > 0;

  setDuplicateIndex(duplicateIndex + 1);

  // there could be different behaviour in the different browses about `onunload` and `onbeforeunload`, so subscribe on both (and use `once` to not run callback twice)
  window.onunload = window.onbeforeunload = once(() => {
    setDuplicateIndex(duplicateIndex);
  });

  return {
    isTabDuplicated,
  };
}
