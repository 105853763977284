import addExperimentsFromQuery from '@wix/santa-main-r/lib/lib/common/addExperimentsFromQuery';
import experimentFactory from '@wix/santa-main-r/lib/lib/common/experimentFactory';
import getQueryUtils from '@wix/santa-main-r/lib/lib/common/getQueryUtils';
import { getLargeNumberOfOpenExperiments } from './getLargeNumberOfOpenExperiments';
import type { Experiment } from 'experiment';

const queryUtil = getQueryUtils(window);

function getExperimentsFromTheUrlQuery() {
  return addExperimentsFromQuery({}, queryUtil, 'editor');
}

const { editorModel } = window;

if (editorModel) {
  editorModel.runningExperiments = {
    ...editorModel.runningExperiments,
    ...getLargeNumberOfOpenExperiments(),
    ...getExperimentsFromTheUrlQuery(),
  };

  editorModel.languageCode =
    queryUtil.getParameterByName('lang') || editorModel.languageCode;
}

export default experimentFactory.build(window) as Experiment;
