import { getPreviewUrl } from './previewUrl';
import type { FedopsLogger } from 'types/fedops';
import type {
  ServiceTopology,
  EditorModel,
  PreviewIframeWindow,
} from 'types/core';
import type { Experiment } from 'experiment';
import type { EditorParams } from '#packages/editorParams';

function insertBefore(newNode: HTMLElement, referenceNode: HTMLElement) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode);
}

function createViewerLoadedPromise({ previewUrl }: { previewUrl: string }) {
  return new Promise<PreviewIframeWindow>((_resolve) => {
    let reloadNeeded = false;
    const onLoaded = (messageEventSource: any) => {
      if (reloadNeeded) {
        return;
      }
      window.removeEventListener('message', previewMessageHandler);
      _resolve(messageEventSource);
    };

    const previewMessageHandler = (event: MessageEvent<unknown>) => {
      if (
        previewUrl.indexOf(event.origin) === 0 &&
        event.data === 'documentServicesLoaded'
      ) {
        onLoaded(event.source);
        return;
      }
      if (
        previewUrl.indexOf(event.origin) === 0 &&
        event.data === 'mobileConversionFailed_ReloadNeeded'
      ) {
        reloadNeeded = true;
        window.location.href = `${window.location.href}&autosaveRestore=false`;
        return;
      }
    };

    window.addEventListener('message', previewMessageHandler);
  });
}

function memo<T>(cb: () => T): () => T {
  let memoizedVal: AnyFixMe;

  return () => {
    if (memoizedVal === undefined) {
      memoizedVal = cb();
    }
    return memoizedVal;
  };
}

const tryReuseRenderedIframe = ({
  fedopsLogger,
}: {
  fedopsLogger: FedopsLogger;
}) => {
  const previewNode = document.querySelector(
    '#preview,[name="preview-frame"]',
  ) as HTMLIFrameElement | null;
  if (!previewNode) {
    return false;
  }
  fedopsLogger.appLoadingPhaseStart('editor-init-stage');
  fedopsLogger.appLoadingPhaseStart('editor-load-viewer');
  fedopsLogger.appLoadingPhaseStart('editor-load-document-service');
  if (window.__previewFrameData) {
    return true;
  }
  const previewUrl = previewNode.getAttribute('src');
  const loadedPromise = createViewerLoadedPromise({
    previewUrl,
  });
  window.__previewFrameData = {
    frameUrl: previewUrl,
    loadedPromise,
    mountAndGetFrame: () => previewNode,
  };

  return true;
};

export const renderPreview = ({
  experiment,
  editorModel,
  serviceTopology,
  fedopsLogger,
}: {
  experiment: Experiment;
  editorModel: EditorModel;
  editorParams: EditorParams;
  serviceTopology: ServiceTopology;
  fedopsLogger: FedopsLogger;
}) => {
  const reused = tryReuseRenderedIframe({ fedopsLogger });
  if (reused) {
    return;
  }
  const previewUrl = getPreviewUrl({
    editorLocation: window.document.location,
    editorModel,
    serviceTopology,
    experiment,
  });
  const loadedPromise = createViewerLoadedPromise({
    previewUrl,
  });

  const mountFrame = () => {
    const rootEl = document.getElementById('root');
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', previewUrl);
    iframe.setAttribute('id', 'preview');
    iframe.setAttribute('allowfullscreen', 'true');
    insertBefore(iframe, rootEl);
    fedopsLogger.appLoadingPhaseStart('editor-init-stage');
    fedopsLogger.appLoadingPhaseStart('editor-load-viewer');
    fedopsLogger.appLoadingPhaseStart('editor-load-document-service');
    // fedopsLogger.appLoadingPhaseStart('editor-viewer-content-visible');
    return iframe;
  };

  const mountAndGetFrame = memo(mountFrame);

  window.__previewFrameData = {
    frameUrl: previewUrl,
    loadedPromise,
    mountAndGetFrame,
  };

  // if (
  //   !editorParams.isInsideEditorX &&
  //   editorModel.willUseTbInPreview === true &&
  //   experiment.isOpen('se_prerenderPreviewUserBased')
  // ) {
  //   mountAndGetFrame();
  // }
};
