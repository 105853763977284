import type { EditorBaseHooks } from '#packages/editorCore';
import type { EditorLoggers } from '../editorLoggers';

import { editorBrowserWindowFocus } from '@wix/bi-logger-editor/v2';

export function registerToTabSwitchEvent({
  editorLoggers,
  editorBaseHooks,
}: {
  editorLoggers: EditorLoggers;
  editorBaseHooks: EditorBaseHooks;
}) {
  window.addEventListener('visibilitychange', function () {
    const isCurrentTabActive = !document.hidden;

    editorBaseHooks.tabChanged.fire({ isCurrentTabActive });

    editorLoggers.bi.logger.report(
      editorBrowserWindowFocus({
        actionName: isCurrentTabActive ? 'suspend' : 'activate',
      }),
    );
  });
}
