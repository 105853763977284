import { WorkerEventAction, type WorkerEventData } from './types';
import type { AliveReportConfig } from './types';

const HEALTH_CHECK_MESSAGE_INTERVAL = 1000;

export type WorkerFactory = () => Worker;

export function initMainThreadHealthCheck(
  workerFactory: WorkerFactory,
  reportConfig: AliveReportConfig,
) {
  const worker = workerFactory();

  function postMessage(data: WorkerEventData) {
    worker.postMessage(data);
  }

  function postInitReporting() {
    postMessage({
      action: WorkerEventAction.Init,
      initConfig: { reportConfig },
    });
  }

  function postIsAlive() {
    postMessage({ action: WorkerEventAction.Alive });
  }

  function postPause() {
    postMessage({ action: WorkerEventAction.Pause });
  }

  function postResume() {
    postMessage({ action: WorkerEventAction.Resume });
  }

  postInitReporting();
  postIsAlive();

  const intervalHandle = setInterval(
    postIsAlive,
    HEALTH_CHECK_MESSAGE_INTERVAL,
  );

  return {
    pause: postPause,
    resume: postResume,
    destroy() {
      clearInterval(intervalHandle);

      worker.terminate();
    },
  };
}

export type MainThreadHealthChecker = ReturnType<
  typeof initMainThreadHealthCheck
>;
