export enum WorkerEventAction {
  Init = 'Init',
  Alive = 'Alive',
  Pause = 'Pause',
  Resume = 'Resume',
}

export interface WorkerInitConfig {
  reportConfig?: AliveReportConfig;
}

export interface WorkerEventData {
  action: WorkerEventAction;
  initConfig?: WorkerInitConfig;
}

// log object type is copied from the
// https://github.com/wix-private/bi-schema-loggers/blob/master/bi-logger-editor/src/v2/types.ts
export interface LogObject<T> {
  evid: number;
  endpoint: string;
  src: number;
  params: T;
}

export interface AliveReportConfig {
  logObject: LogObject<any>;
  timeouts: number[];
}
