import { factory } from '@wix/web-bi-logger';
import { EDITOR_BI_ENDPOINT, EDITOR_BI_SOURCE } from './constants';
import { createLegacyBiLogger } from './biLoggerLegacy';
import { getBILoggerInitialParams } from './getBILoggerInitialParams';

import type { EditorModel } from 'types/core';
import type { EditorParams } from '#packages/editorParams';
import type { LoggerOptions } from '../types';

function mapValues(
  object: Record<string, any>,
  iteratee: (val: any, key: string) => any,
) {
  const result: any = {};
  Object.keys(object).forEach((key) => {
    result[key] = iteratee(object[key], key);
  });
  return result;
}

const BI_PARAM_HARD_LIMIT = 15 * 1024; //15kb (experiments in 429 is 11kb)

export const createBiLogger = ({
  editorModel,
  editorParams,
  options: { useBatch },
}: {
  editorModel: EditorModel;
  editorParams: EditorParams;
  options: LoggerOptions;
}) => {
  const initialDefaults = getBILoggerInitialParams({
    editorModel,
    editorParams,
  });

  const biLoggerFactory = factory({
    endpoint: EDITOR_BI_ENDPOINT,
    useBatch,
  })
    .withTransformer((params) => {
      return mapValues(params, (param, paramName) => {
        if (typeof param === 'object' && param !== null) {
          try {
            param = JSON.stringify(param);
          } catch (err: any) {
            param = '<NOT_SERIALIZABLE>';
            console.error(
              `BI param is not sent due to serialization error, paramName: ${paramName}, evid: ${params.evid}, errMsg: ${err.message}`,
            );
          }
        }
        if (typeof param === 'string' && param.length > BI_PARAM_HARD_LIMIT) {
          param = '<TOO_LONG>';
          console.error(
            `BI param is not sent due to hard limit, paramName: ${paramName}, evid: ${params.evid}`,
          );
        }
        return param;
      });
    })
    .setMuted(!editorParams.isBiErrorsAndFedopsEnabled)
    .updateDefaults({
      src: EDITOR_BI_SOURCE,
      ...initialDefaults,
    });

  const biLogger = biLoggerFactory.logger();

  return {
    logger: biLogger,
    loggerLegacy: createLegacyBiLogger(biLogger),
    _initialDefaults: initialDefaults,
    updateDefaults: (params: Record<string, any>) => {
      biLoggerFactory.updateDefaults(params);
    },
  };
};
