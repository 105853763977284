// eslint-disable-next-line @wix/santa-editor/scoped-imports
import { createHook, createPromiseHook } from '@/apilib/hooks';

import type { EditorBaseHooks } from '#packages/editorCore';

export const createEditorBaseHooks = (): EditorBaseHooks => ({
  tabChanged: createHook(),
  siteCreationCreatePresets: createHook(),
  editorBuildAndRender: createPromiseHook(),
  createInstallAppsHooks: createPromiseHook(),
  wizardFinish: createPromiseHook(),
  sectionsMigrationStarted: createPromiseHook<{
    migrationSkipCount: number;
  }>(),
  sectionsMigrationReady: createPromiseHook<{
    isMigrated: boolean;
    error?: Error | unknown;
  }>(),
  UNSTABLE_sectionsMigrationFailedOnHomePage: createPromiseHook(),
  isSiteCreationUiVisible: createPromiseHook(),
});
