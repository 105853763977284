import {
  EDITOR_BI_ENDPOINT,
  EDITOR_BI_ERROR_ENDPOINT,
  EDITOR_BI_SOURCE,
} from './constants';
import { getCookie } from './utils';
import type { LegacyBiLogger } from 'types/bi';
import type { BiLogger } from './types';

export function createLegacyBiLogger(biLogger: BiLogger): LegacyBiLogger {
  const event: LegacyBiLogger['event'] = (definition, params) => {
    if (typeof definition === 'number') {
      definition = { evid: definition } as any;
    }
    const endpoint = definition.endpoint || EDITOR_BI_ENDPOINT;

    return biLogger.log(
      {
        src: definition.src || EDITOR_BI_SOURCE,
        evid: definition.evid,
        ...params,
      },
      {
        endpoint,
      },
    );
  };

  const error: LegacyBiLogger['error'] = (definition, params) => {
    const errorSeverityMap = {
      recoverable: 10,
      warning: 20,
      error: 30,
      fatal: 40,
    };

    const _definition = {
      evid: 10,
      endpoint: definition.endpoint || EDITOR_BI_ERROR_ENDPOINT,
    };

    const _params = {
      errn: definition.errorName,
      errc: definition.errorCode,
      sev:
        errorSeverityMap[
          definition.severity as keyof typeof errorSeverityMap
        ] || definition.severity,
      // https://github.com/wix-private/document-management/blob/70c18ca6e33fc87e9bac09f10e6032ce4ff81333/tb-main/src/bi.ts#L111-L112
      // https://github.com/wix-private/santa-editor/blob/a70e821941ddf79ba5589f19069d444ea8b72dfc/santa-editor/packages/main/startup/biLogger.ts#L86-L115
      cat: 1,
      iss: 1,
      ut: getCookie('userType') || undefined,
      ...params,
    };

    return event(_definition, _params);
  };

  return {
    event,
    error,
  };
}
