export default function identifyForFullStory(
  fullStory: AnyFixMe,
  editorModel: AnyFixMe,
) {
  fullStory.identify(editorModel.editorSessionId, {
    userId: editorModel.permissionsInfo.loggedInUserId,
    userAgent: window.navigator.userAgent,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
  });
}
