import { initMainThreadHealthCheck, type WorkerFactory } from './healthCheck';
import { editorStuck } from '@wix/bi-logger-editor/v2';
import { pickByValuePredicate } from './utils';
import type { ValuesOfReturnFnValues } from 'types/core';
import type { BiLoggerInitialParams } from 'types/bi';
import type { EditorParams } from '#packages/editorParams';
import type { EditorBaseHooks } from '#packages/editorCore';

const ALIVE_TIMEOUTS = [10 /*sec*/ * 1000, 30 * 1000, 60 * 1000];

export function initHealthCheck(
  workerFactory: WorkerFactory,
  {
    editorBaseHooks,
    biDefaults,
    editorParams,
  }: {
    editorBaseHooks: EditorBaseHooks;
    biDefaults: ValuesOfReturnFnValues<BiLoggerInitialParams>;
    editorParams: EditorParams;
  },
) {
  if (editorParams.isInsideEditorX) {
    return;
  }

  // filter out unresolved default bi params
  const biDefaultsPlain: Partial<BiLoggerInitialParams> = pickByValuePredicate(
    biDefaults,
    (value) => typeof value !== 'function',
  );

  const mainThreadHealthChecker = initMainThreadHealthCheck(workerFactory, {
    timeouts: ALIVE_TIMEOUTS,
    logObject: editorStuck(biDefaultsPlain),
  });

  editorBaseHooks.tabChanged.tap(({ isCurrentTabActive }) => {
    if (!isCurrentTabActive) {
      mainThreadHealthChecker.pause();
    } else {
      mainThreadHealthChecker.resume();
    }
  });

  editorBaseHooks.siteCreationCreatePresets.tap(
    ({ isCreatePresetsInProgress }) => {
      if (isCreatePresetsInProgress) {
        mainThreadHealthChecker.pause();
      } else {
        mainThreadHealthChecker.resume();
      }
    },
  );
}
