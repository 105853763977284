import type { EditorParams } from '#packages/editorParams';

export function initResourceTimingBuffer(editorParams: EditorParams) {
  const performance = window.performance;

  if (!performance || !performance?.setResourceTimingBufferSize) {
    return;
  }

  if (!performance.onresourcetimingbufferfull) {
    performance.onresourcetimingbufferfull = (() => {
      let maxSize = 150;

      return () => {
        maxSize *= 2;
        performance.setResourceTimingBufferSize(maxSize);
      };
    })();
  } else {
    performance.setResourceTimingBufferSize(editorParams.isDebug ? 1000 : 300);
  }
}
