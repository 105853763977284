import type { Experiment } from 'experiment';
import type { EditorParams } from '#packages/editorParams';

// migration version is declared here
// https://github.com/wix-private/santa-editor/blob/3a5477181bb346a81ef00cb96c8fa5c7a1addffd/santa-editor/packages/sectionsMigration/version.ts
const MIGRATION_VERSION = '0.0.9';

export const canShowHeavySitesMigrationLoader = ({
  editorParams,
  experiment,
  queryUtil,
}: {
  editorParams: EditorParams;
  experiment: Experiment;
  queryUtil: any;
}) => {
  const isPerPageMigrationFlow =
    editorParams.sectionsMigrationFlow === 'perPage_editor' ||
    editorParams.sectionsMigrationFlow === 'perPage_adi';

  if (isPerPageMigrationFlow) {
    return true;
  }

  const isTriggeredByUser = queryUtil.isParameterTrue('forceSectionsMigration');
  const isHeavySitesMigrationOpen = experiment.isOpen(
    'se_heavySitesMigrationLoader',
  );
  const isEditorOrHeavySiteFlow =
    editorParams.sectionsMigrationFlow === 'heavySite' ||
    editorParams.sectionsMigrationFlow === 'editor';
  const isAdiFlow =
    editorParams.sectionsMigrationFlow === 'adi' &&
    queryUtil.getParameterByName('migrationTrigger') === 'reminder';
  const hasOutdatedMigrationVersion =
    editorParams.sectionsMigrationVersion !== MIGRATION_VERSION;

  const canShowLoader =
    (isTriggeredByUser || isHeavySitesMigrationOpen) &&
    (isEditorOrHeavySiteFlow || isAdiFlow) &&
    hasOutdatedMigrationVersion;

  return canShowLoader;
};
