import { ErrorReporter } from '@wix/editor-error-reporter';
import { loadMadeforFont } from '../utils/loadMadeforFont';
import { canShowHeavySitesMigrationLoader } from './utils/canShowHeavySitesMigrationLoader';

// NOTE: ensure only type imported. The size of initial loader should be minimal
import type { FedopsLogger } from 'types/fedops';
import type { InitInitialLoaderResult } from 'types/initialLoader';
import type { Experiment } from 'experiment';

import type { EditorParams } from '#packages/editorParams';
import type { EditorBaseHooks } from '#packages/editorCore';
import type { LoaderTimelineMode } from '../progressiveInitialLoader/types';
import type { LoadI18n } from '../types';
import type { BiLogger } from '../editorLoggers/loggers/types';

export function initInitialLoader({
  editorParams,
  editorBaseHooks,
  experiment,
  fedopsLogger,
  biLogger,
  queryUtil,
  loadI18n,
}: {
  editorParams: EditorParams;
  experiment: Experiment;
  editorBaseHooks: EditorBaseHooks;
  fedopsLogger: FedopsLogger;
  biLogger: BiLogger;
  queryUtil: any;
  loadI18n: LoadI18n;
}) {
  const loadFont = () => {
    void loadMadeforFont();
  };

  if (
    canShowHeavySitesMigrationLoader({ editorParams, experiment, queryUtil })
  ) {
    loadFont();
    return initHeavySitesMigrationLoader({
      editorBaseHooks,
      editorParams,
      loadI18n,
      biLogger,
    });
  }

  if (
    editorParams.sectionsMigrationFlow === 'editor' &&
    !experiment.isOpen('se_heavySitesMigrationLoader')
  ) {
    loadFont();
    return initSectionsMigrationInitialLoader({
      editorBaseHooks,
      loadI18n,
    });
  }

  const canShowProgressiveLoader =
    !editorParams.isInsideEditorX &&
    !editorParams.isInsideAppStudio &&
    !editorParams.siteCreationWizard &&
    !editorParams.siteGenerationWizard;

  // NOTE: enable progressive loader only for editor2 experience
  // https://wix.slack.com/archives/C01CN7UT34Y/p1656406265632019?thread_ts=1656402116.882559&cid=C01CN7UT34Y
  if (canShowProgressiveLoader && editorParams.isSectionsExperienceEnabled) {
    loadFont();
    return initProgressiveInitialLoader({
      editorBaseHooks,
      fedopsLogger,
      loadI18n,
    });
  }

  return initBaseInitialLoader();
}

// NOTE: base initial loader is rendered from the server side
const getLoaderContainer = () => {
  const element = window.document.getElementById(
    'video-preloader',
  ) as HTMLDivElement;
  return {
    element,
    remove: () => element?.parentNode?.removeChild(element),
  };
};

function initBaseInitialLoader(): InitInitialLoaderResult {
  const loaderContainer = getLoaderContainer();

  return {
    unmount: () => {
      loaderContainer.remove();
    },
  };
}

function initSectionsMigrationInitialLoader({
  editorBaseHooks,
  loadI18n,
}: {
  editorBaseHooks: EditorBaseHooks;
  loadI18n: LoadI18n;
}): InitInitialLoaderResult {
  const loaderContainer = getLoaderContainer();
  const mountLoaderPromise = import(
    '../sectionsMigration/sectionMigrationInitialLoader'
  ).then(({ mountLoader }) =>
    mountLoader({
      loadI18n,
      editorBaseHooks,
    }),
  );

  return {
    unmount: () => {
      mountLoaderPromise.then(({ unmount }) => {
        unmount();
      });
      loaderContainer.remove();
    },
  };
}

function initHeavySitesMigrationLoader({
  editorBaseHooks,
  loadI18n,
  biLogger,
  editorParams,
}: {
  editorBaseHooks: EditorBaseHooks;
  loadI18n: LoadI18n;
  biLogger: BiLogger;
  editorParams: EditorParams;
}): InitInitialLoaderResult {
  const loaderContainer = getLoaderContainer();

  const mountLoaderPromise = import(
    '../sectionsMigration/heavySitesMigrationLoader'
  ).then(({ mountLoader }) =>
    mountLoader({
      editorBaseHooks,
      loaderContainer: loaderContainer.element,
      loadI18n,
      biLogger,
      flow: editorParams.sectionsMigrationFlow,
    }),
  );

  return {
    unmount() {
      if (window.__IS_MIGRATION_FAILED__) {
        //timeout is needed here in case of tech error to show the user screen with error reason for 7s and not instantly unmount the loader when editor is ready
        setTimeout(() => {
          mountLoaderPromise.then(({ unmount }) => {
            unmount();
          });
          loaderContainer.remove();
        }, 7000);
      } else {
        mountLoaderPromise.then(({ unmount }) => {
          unmount();
        });
        loaderContainer.remove();
      }
    },
  };
}

function initProgressiveInitialLoader({
  editorBaseHooks,
  fedopsLogger,
  timelineMode,
  loadI18n,
}: {
  editorBaseHooks: EditorBaseHooks;
  fedopsLogger: FedopsLogger;
  timelineMode?: LoaderTimelineMode;
  loadI18n: LoadI18n;
}): InitInitialLoaderResult {
  const loaderContainer = getLoaderContainer();

  const reportError = (error: Error): void => {
    ErrorReporter.captureException(error, {
      tags: {
        operation: 'progressive-initial-loader',
      },
    });
  };

  fedopsLogger.appLoadingPhaseStart('main-chunk-init-initial-loader');

  const mountLoaderPromise = import('../progressiveInitialLoader').then(
    async ({ mountLoader }) =>
      mountLoader({
        timelineMode,
        timelinePendingPhases: {
          'leftBar.original': editorBaseHooks.editorBuildAndRender.promise,
        },
        loaderContainer: loaderContainer.element,
        reportError,
        loadI18n,
      }),
  );

  mountLoaderPromise.then(
    () => {
      fedopsLogger.appLoadingPhaseFinish('main-chunk-init-initial-loader');
    },
    (error) => {
      reportError(error);
    },
  );

  return {
    unmount() {
      mountLoaderPromise.then(({ unmount }) => {
        unmount();
      });

      loaderContainer.remove();
    },
  };
}
