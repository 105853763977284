export interface PermissionsRead<TPermissionName extends string> {
  has(permissionName: TPermissionName): boolean;
}

interface PermissionsReadOnlyOptions<TPermissionName> {
  permissions?: Array<TPermissionName>;
}

export class PermissionReadOnly<TPermissionName extends string>
  implements PermissionsRead<TPermissionName>
{
  private store: Set<TPermissionName>;
  constructor({
    permissions,
  }: PermissionsReadOnlyOptions<TPermissionName> = {}) {
    this.store = new Set<TPermissionName>(permissions);
  }
  has(permissionName: TPermissionName): boolean {
    if (!permissionName) {
      throw new TypeError('Parameter "permissionName" not provided');
    }
    return this.store.has(permissionName);
  }
}
