// https://github.com/guybedford/es-module-shims#polyfill-edge-case-dynamic-import -- entry chunk should have static module import from importmap
// working in pair with https://github.com/wix-private/responsive-editor/blob/f793c31da856cf6a285ce0318be14973c82b37f1/packages/responsive-editor/src/assets/classic.vm#L134
import 'trigger-importmap-shim';
import getQueryUtils from '@wix/santa-main-r/lib/lib/common/getQueryUtils';
import joinURL from '@wix/santa-main-r/lib/lib/common/joinURL';
import overrideScriptsLocationMapFromQuery from '@wix/santa-main-r/lib/lib/common/overrideScriptsLocationMapFromQuery';
import storageUtil from '@wix/santa-main-r/lib/lib/common/storageUtil';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { htmlLoadingStepMainRLoaded } from '@wix/bi-logger-editor/v2';

// eslint-disable-next-line @wix/santa-editor/scoped-imports
import type { StartupConfig } from '../common';
import { initEditor } from '../entry';
import { createEditorLoggers } from './editorLoggers';
import { renderPreview } from './renderIframe/renderIframe';
import {
  getBaseVersion,
  getParamFromUrl,
  registerToTabLeaveEvent,
  getBaseUIOverrides,
} from './utils';
import identifyForFullStory from './identifyForFullStory';
import instrument from './instrument';
import experiment from './experiment';
import { PermissionReadOnly } from './permissions';
import { createErrorEventProcessor } from './errorEventProcessor';
import { getSectionsMigrationFlow } from './sectionsMigration';
import { initTabDuplicatePolice } from './tabDuplicatePolice';
import { initHealthCheck } from './initHealthCheck';
import { getIsSectionsExperienceEnabled } from './sectionsExperience';
import { registerToTabSwitchEvent } from './utils/registerToTabSwitchEvent';
import { initInitialLoader } from './initialLoader';
import { createEditorBaseHooks } from './baseHooks';
//@ts-expect-error
import HealthCheckWorker from './healthCheck/worker/healthCheck.worker';

const { versions } = require('../../../gen/options.json');

import type { EditorParams } from '#packages/editorParams';
import type { EditorBaseHooks } from '#packages/editorCore';
export type { PermissionReadOnly } from './permissions';

const queryUtil = getQueryUtils(window);
window.joinURL = joinURL;
window.queryUtil = queryUtil;
window.persistent = storageUtil(window); // TODO: how is this used?
window.identifyForFullStory = identifyForFullStory;

function removeTrailingSlash(obj: Record<string, string>) {
  const resObj: Record<string, string> = {};
  Object.keys(obj).forEach((key) => {
    resObj[key] = obj[key].replace(/\/$/, '');
  });
  return resObj;
}

window.serviceTopology.scriptsLocationMap = removeTrailingSlash(
  window.serviceTopology.scriptsLocationMap,
);

if (window.customRenderSite !== undefined) {
  // allows to override this in other html files as starting points
  window.customRenderSite();
}

const setEditorTabName = (metaSiteId: string) => {
  window.name = `editor-${metaSiteId}`;
};

if (!window.name) {
  setEditorTabName(window.editorModel.metaSiteId);
}
window.addEventListener('beforeunload', () => {
  window.name = '';
  setTimeout(setEditorTabName.bind(null, window.editorModel.metaSiteId), 1000);
});

function applyTopologyOverriding() {
  const versionRegExp = /1\.\d+\.\d+/g;
  window.serviceTopology.scriptsLocationMap['santa-langs'] =
    window.serviceTopology.scriptsLocationMap['santa-langs'].replace(
      versionRegExp,
      versions['santa-langs'],
    );
  window.serviceTopology.scriptsLocationMap =
    overrideScriptsLocationMapFromQuery(
      window.serviceTopology.scriptsLocationMap,
      queryUtil.getParameterByName('scriptsLocations'),
    );
}

const isLocal = (path: string) =>
  /^(https?:)?\/\/localhost|local.wix.com($|[/:])/.test(path);

// //used by editorx bridge
// export async function startupResponsive({
//   publicPath,
// }: {
//   publicPath: string;
// }) {
//   // window.__dont_inject_css_extract_plugin__ = true;
//   window.__webpack_overridable_esm_modules__ = true; //used by AllowMutateEsmExports plugin

//   return _startup({
//     isInsideEditorX: true,
//     loadMainCss: false,
//     measureFrameRate: false,
//     shouldBiErrorsAndFedops: false,
//     shouldRender: false,
//     shouldLoadPolyfills: false,
//     publicPath,
//     stylablePanelTheme: 'editorx-theme',
//   }, editorBaseHooks);
// }

export async function startup(config: StartupConfig) {
  const editorBaseHooks = createEditorBaseHooks();

  try {
    const res = await _startup(config, editorBaseHooks);
    return res;
  } catch (error) {
    editorBaseHooks.editorBuildAndRender.reject(error);
    console.error(error);
    ErrorReporter.captureException(error, {
      extra: getImportMapsExtra(),
      tags: {
        failedToLoadEditor: true,
        operation: 'editor-bootstrap',
      },
    });
  }
}

function getImportMapsExtra() {
  try {
    const supports = HTMLScriptElement.supports;
    const supportsImportMaps = Boolean(
      supports && supports.name === 'supports' && supports('importmap'),
    );
    const nativeImportMap = document.querySelector('script[type="importmap"]');
    const importMapShim = document.querySelector(
      'script[type="importmap-shim"]',
    );

    const hasNativeImportMap = Boolean(nativeImportMap);

    return {
      supportsImportMaps,
      hasNativeImportMap,
      hasImportMapShim: Boolean(importMapShim),
      esmShimPolyfillTriggered: !supportsImportMaps && hasNativeImportMap,
      userAgent: navigator.userAgent,
    };
  } catch (err: any) {
    return {
      getErrorExtraError: err?.message,
    };
  }
}

async function _startup(
  config: StartupConfig,
  editorBaseHooks: EditorBaseHooks,
) {
  const { shouldBiErrorsAndFedops } = config;

  window.afterEditorPrefetch = false; //TODO remove this;

  const {
    loggerModel: biLoggerModel,
    editorModel,
    appStudioModel,
    serviceTopology,
  } = window;
  const { isTabDuplicated } = initTabDuplicatePolice();
  const isInsideEditorX = config.isInsideEditorX;
  const isInsideAppStudio = appStudioModel !== undefined;

  if (
    isInsideAppStudio &&
    !isInsideEditorX &&
    experiment.isOpen('se_appBuilderSunset')
  ) {
    location.replace('https://www.wix.com/blocks/app-builder-sunset');
    return;
  }

  const isSectionsExperienceEnabled = getIsSectionsExperienceEnabled({
    isInsideEditorX,
    isInsideAppStudio,
    editorModel,
    experiment,
  });

  const sectionsMigrationFlow = isSectionsExperienceEnabled
    ? undefined
    : getSectionsMigrationFlow({
        isInsideEditorX,
        isInsideAppStudio,
        editorModel,
        experiment,
        queryUtil,
      });

  // TODO: use `editorParams` instead of `queryUtil.getParameterByName('x')` across the whole code
  const editorParams: EditorParams = {
    isInsideEditorX,
    isInsideAppStudio,
    isSectionsExperienceEnabled,
    sectionsMigrationFlow,
    sectionsMigrationVersion: editorModel.siteHeader.migrationVersion,
    baseUIOverrides: getBaseUIOverrides(
      queryUtil.getParameterByName('baseUIOverrides'),
    ),
    appsToInstall: getParamFromUrl('appsToInstall'),
    isLocal: isLocal(queryUtil.getParameterByName('EditorSource')),
    isDebug:
      queryUtil.getParameterByName('debug') ||
      localStorage?.getItem('editor-debug'),
    isQA: queryUtil.isParameterTrue('isqa'),
    siteGenerationWizard:
      queryUtil.getParameterByName('siteGenerationWizard') === 'true' &&
      editorModel.isSiteCreationEligible,
    siteCreationWizard:
      queryUtil.getParameterByName('siteCreationWizard') === 'true' &&
      editorModel.isSiteCreationEligible,
    originalTemplateId: editorModel.siteHeader.originalTemplateId,
    isTestRunner: queryUtil.isParameterTrue('istestrunner'),
    isBiErrorsAndFedopsEnabled:
      !queryUtil.isParameterTrue('suppressbi') && shouldBiErrorsAndFedops,
    isRollout: Boolean(biLoggerModel.is_rollout), // Boolean(0) === false
    shouldMeasureFrameRate: config.measureFrameRate,
    viewerName: biLoggerModel.origin,
    esi: editorModel.editorSessionId,
    siteId: editorModel.siteHeader?.id,
    metaSiteId: editorModel.metaSiteId || biLoggerModel.metaSiteId,
    isTabDuplicated,
  };

  if (editorModel) {
    editorModel.languageCode =
      queryUtil.getParameterByName('lang') || editorModel.languageCode;

    editorModel.editorVersion = getBaseVersion(editorModel.editorBase);
    editorModel.editorBase = editorModel.editorBase.replace(/\/$/, ''); // remove after esbuild experiment is merged and rewrite all usages
  }

  const editorLoggers = createEditorLoggers({
    biLoggerModel,
    editorParams,
    editorModel,
    options: {
      useBatch: true,
    },
  });

  const fedopsLogger = editorLoggers.fedops.logger;
  const biLogger = editorLoggers.bi.logger;

  window.__editor_bi_loggers__ = editorLoggers.bi;
  (<any>window).EDITOR_EXPERIMENT_MODULE = experiment;

  // esbuild inlineWorkerPlugin
  initHealthCheck(() => HealthCheckWorker() as any, {
    editorBaseHooks,
    biDefaults: editorLoggers.bi._initialDefaults,
    editorParams,
  });

  ErrorReporter._addEventProcessor(createErrorEventProcessor({ fedopsLogger }));

  window.mainLoaded = Date.now();
  fedopsLogger.appLoadingPhaseStart('main-chunk');

  applyTopologyOverriding();

  renderPreview({
    experiment,
    editorModel,
    editorParams,
    serviceTopology,
    fedopsLogger,
  });

  instrument({
    window,
    requirejs: undefined,
    queryUtil,
    editorLoggers,
    editorModel,
    editorParams,
  });

  registerToTabSwitchEvent({
    editorLoggers,
    editorBaseHooks,
  });

  registerToTabLeaveEvent({ editorLoggers });

  if (editorModel) {
    const { performance } = window;
    const navigation = performance ? performance.navigation : undefined;
    const navigationType = navigation ? navigation.type : undefined;
    //newer implementation window.performance.getEntriesByType("navigation")[0].type;
    void biLogger.report(
      htmlLoadingStepMainRLoaded({
        navigationType,
        jsHeapSizeLimit: JSON.stringify(getImportMapsExtra()),
      }),
    );
  }

  // @ts-expect-error
  const loadI18n = () => import('json!langs').then((mod) => mod.default);

  const initialLoader = initInitialLoader({
    editorParams,
    editorBaseHooks,
    experiment,
    fedopsLogger,
    biLogger,
    queryUtil,
    loadI18n,
  });

  window.initialLoader = initialLoader;

  if (editorParams.siteGenerationWizard) {
    // there is a bug in safari.
    // Basically, when js files are in cache, when two concurrent import('./entry') is executing, it cannot find dep from importmap.
    // There is some race.
    // Error: Importing module 'esm-shim-runtime' is not found.
    // thats why we need await here
    await import('./siteGeneration').then(
      async ({ startSiteGenerationWizard }) =>
        await startSiteGenerationWizard({
          editorBaseHooks,
          loadI18n,
          unmountInitialLoader: initialLoader.unmount,
          fedopsLogger,
          isDebugMode: editorParams.isDebug,
        }),
    );
  }

  if (editorParams.siteCreationWizard) {
    // there is a bug in safari.
    // Basically, when js files are in cache, when two concurrent import('./entry') is executing, it cannot find dep from importmap.
    // There is some race.
    // Error: Importing module 'esm-shim-runtime' is not found.
    // thats why we need await here
    await import('./siteCreation').then(({ renderSiteCreation }) =>
      renderSiteCreation({
        unmountInitialLoader: initialLoader.unmount,
        hooks: editorBaseHooks,
      }),
    );
  }

  const editorPermissions = new PermissionReadOnly<string>({
    permissions: editorModel.permissionsInfo.permissions,
  });

  const { builtEditor } = await initEditor(
    {
      editorLoggers,
      editorModel,
      editorParams,
      editorPermissions,
      editorBaseHooks,
    },
    config,
  );
  editorBaseHooks.editorBuildAndRender.resolve();
  fedopsLogger.appLoadingPhaseFinish('main-chunk');
  return builtEditor;
}
