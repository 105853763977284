import type { EventProcessor } from '@wix/editor-error-reporter';

export function createErrorEventProcessor({
  fedopsLogger,
}: {
  fedopsLogger: {
    reportErrorThrownOncePerFlow: () => void;
    _getFlowsOfError?: () => string[];
  };
}): EventProcessor {
  return (event) => {
    // https://github.com/wix-private/fed-infra/tree/master/fedops/fedops-logger#client-side-errors-sentry
    fedopsLogger.reportErrorThrownOncePerFlow();

    // Get current fedops interactions or phase (used internal api of fedopsLogger.reportErrorThrownOncePerFlow())
    // https://github.com/wix-private/fed-infra/tree/0fea1fe0fc2138b637bd676e9a4be236f3869610/fedops/fedops-logger#error-rate
    // https://github.com/wix-private/fed-infra/blob/0fea1fe0fc2138b637bd676e9a4be236f3869610/fedops/fedops-logger/src/base-logger.js#L834
    // TODO: review after discussion https://wix.slack.com/archives/C3RTVMWV8/p1618500580095100
    const fedopsInteractionsOrPhases = fedopsLogger._getFlowsOfError?.() || [];

    const [
      fedopsInteractionOrPhase,
      fedopsInteractionOrPhase2,
      fedopsInteractionOrPhase3,
    ] = fedopsInteractionsOrPhases;

    event.tags = {
      ...event.tags,
      fedopsInteractionOrPhase,
      fedopsInteractionOrPhase2,
      fedopsInteractionOrPhase3,
    };

    event.extra = {
      ...event.extra,
      fedopsInteractionsOrPhases,
    };

    return event;
  };
}
